/**
 * 短剧批量管理api接口统一管理
 */
import { get, post } from '../https.js'

//= ====================================toutiao======================================

/**
  * 同步视频到标题组
  * @param {*} data
  * @returns
  */
export const syncTitleGroup = (data) => post('/toutiaoGroupTitle/syncTitleGroup', data)

/**
  * 头条查询标题组
  * @param {*} data
  * @returns
  */
export const selectGroupTitle = (data) => get('/toutiaoGroupTitle/selectGroupTitle', data)

/**
  * 分享标题组
  * @param {*} data
  * @returns
  */
export const shareTitleGroup = (data) => post('/toutiaoGroupTitle/shareTitleGroup', data)

/**
  * 修改标题组名称
  * @param {*} data
  * @returns
  */
export const updateGroupTitleName = (data) => get('/toutiaoGroupTitle/updateGroupTitleName', data)

/**
  * 通过用户名模糊查询获取用户信息
  * @param {*} data
  * @returns
  */
export const selectAndNickName = (data) => get('/toutiaoGroupTitle/selectAndNickName', data)


/**
  * 复制标题组
  * @param {*} data
  * @returns
  */
export const copyTitleGroup = (data) => get('/toutiaoGroupTitle/copyTitleGroup', data)


/**
  * 通过部门id获取用户信息
  * @param {*} data
  * @returns
  */
export const selectAndDeptId = (data) => get('/toutiaoGroupTitle/selectAndDeptId', data)


/**
  * 查询当前用户所在组织 最上级别
  * @param {*} data
  * @returns
  */
export const queryOrganization = (data) => get('/toutiaoGroupTitle/queryOrganization', data)


/**
  * 编辑文案
  * @param {*} data
  * @returns
  */
export const updateTitleSucai = (data) => post('/toutiaoGroupTitle/updateTitleSucai', data)


/**
  * 删除标题组
  * @param {*} data
  * @returns
  */
export const deleteGroupTitle = (data) => get('toutiaoGroupTitle/deleteGroupTitle', data)


/**
  * 输入广告主id查询是否存在
  * @param {*} data
  * @returns
  */
export const selectAccountIdIsNotExsit = (data) => get('/v2/Toutiaoplanv2/selectAccountIdIsNotExsit', data)


/**
  * 添加标题组
  * @param {*} data
  * @returns
  */
export const insertGroupTitle = (data) => post('toutiaoGroupTitle/insertGroupTitle', data)

/**
  * 上传视频
  * @param {*} data
  * @returns
  */
export const uploadVideo = (data) => post('/toutiaoGroupTitle/uploadVideo', data)


/**
* 通过标题组id查询素材
* @param {*} data
* @returns
*/
export const selectGroupTitleSucai = (data) => get('/toutiaoGroupTitle/selectGroupTitleSucai', data)

/**
* 删除标题组里面的素材内容
* @param {*} data
* @returns
*/
export const deleteGroupTitleSucai = (data) => get('/toutiaoGroupTitle/deleteGroupTitleSucai', data)

/**
   * 添加标题组里面素材内容
   * @param {*} data
   * @returns
   */
export const insertGroupTitleSucai = (data) => post('/toutiaoGroupTitle/insertGroupTitleSucai', data)

/**
   * 获取橙子建站落地页信息
   * @param {*} data
   * @returns
   */
export const getSite = (data) => get('/Toutiaoplan/getSite', data)

/**
   * 查询广告计划可用转化目标
   * @param {*} data
   * @returns
   */
export const convertQuery = (data) => get('/Toutiaoplan/convertQuery', data)


/**
* 创意分类  查询行业列表
* @param {*} data
* @returns
*/
export const selectIndustry = (data) => get('/Toutiaoplan/selectIndustry', data)


/**
* 查询组件卡片
* @param {*} data
* @returns
*/
export const getCard = (data) => get('/Toutiaoplan/getCard', data)


/**
* 首选媒体（广告计划-头条）
* @param {*} data
* @returns
*/
export const selectToutiaoMedia = (data) => get('/mediaDictonary/selectToutiaoMedia', data)


/**
* 定向包查询2
* @param {*} data
* @returns
*/
export const selectDirectionalNotPage = (data) => get('/Toutiaoplan/selectDirectionalNotPage', data)


/**
* 创建头条广告计划
* @param {*} data
* @returns
*/
export const createPlan = (data) => post('/Toutiaoplan/createPlan', data)

/**
* 创建头条广告计划
* @param {*} data
* @returns
*/
export const selectToutiaoPlanResult = (data) => get('/Toutiaoplan/selectToutiaoPlanResult', data)

/**
<<<<<<< HEAD
* 创建创建卡片
* @param {*} data
* @returns
*/
export const createCard = (data) => post('/toutiaoGroupTitle/createCard', data)

/**
* 创建头条广告计划
* @param {*} data
* @returns
*/
export const selectSonData = (data) => get('/Toutiaoplan/selectSonData', data)

/**
* 事件管理 获取橙子建站落地页信息 审核通过
* @param {*} data
* @returns
*/
export const getSite2 = (data) => get('/Toutiaoplan/getSite2', data)

/**
* 事件管理 获取致资产
* @param {*} data
* @returns
*/
export const geteventConvert = (data) => get('/Toutiaoplan/geteventConvert', data)

/**
* 商品时事件管理 获取致资产
* @param {*} data
* @returns
*/
export const getGoodEventConvert = (data) => get('/Toutiaoplan/getGoodEventConvert', data)

/**
* 商品时优化目标
* @param {*} data
* @returns
*/
export const getGoodEventSite = (data) => get('/Toutiaoplan/getGoodEventSite', data)

// 查询优化目标
export const getV2optimizedGoal = (data) => get('/v2/Toutiaoplanv2/getV2optimizedGoal', data)

/**
* 获取字节小程序
* @param {*} data
* @returns
*/
export const getV2MicroApp = (data) => get('/v2/Toutiaoplanv2/getV2MicroApp', data)

/**
* 获取微信小程序
* @param {*} data
* @returns
*/
export const getWechatAppletList = (data) => get('/v2/Toutiaoplanv2/getWechatAppletList', data)

/**
* 获取事件资产
* @param {*} data
* @returns
*/
export const getV2Assets = (data) => get('/v2/Toutiaoplanv2/getV2Assets', data)


 /**
  * 创建字节小程序
  * @param {*} data
  * @returns
  */
 export const createMicroApp = (data) => post('/v2/Toutiaoplanv2/createMicroApp', data)


  /**
  * 新增字节小程序链接
  * @param {*} data
  * @returns
  */
  export const createMicroAppLink = (data) => post('/v2/Toutiaoplanv2/createMicroAppLink', data)


   /**
  * 创建微信小程序
  * @param {*} data
  * @returns
  */
 export const createWechatApplet = (data) => post('/v2/Toutiaoplanv2/createWechatApplet', data)








//= ====================================toutiao====>end==================================


// 通过优化目标获取橙子落地页站点信息
export const getV2orangeSite = (data) => get('/v2/Toutiaoplanv2/getV2orangeSite', data)


// 创建头条广告计划2.0
export const createV2Plan = (data) => post('/v2/Toutiaoplanv2/createV2Plan', data)

// 定时创建头条广告计划2.0
export const scheduleCreateV2Plan = (data) => post('/v2/Toutiaoplanv2/scheduleCreateV2Plan', data)

// 定时创建头条广告
export const scheduleCreateV2Promotion = (data) => post('/v2/Toutiaoplanv2/scheduleCreateV2Promotion', data)

// 定时发布页面
export const selectToutiaoScheduleInfo = (data) => get('/v2/Toutiaoplanv2/selectToutiaoScheduleInfo', data)

// 删除定时发布
export const deleteToutiaoScheduleInfo = (data) => get('/v2/Toutiaoplanv2/deleteToutiaoScheduleInfo', data)
//= ====================================toutiao====>end==================================

// 查询商品库信息
export const getProductAvailables = (data) => get('/v2/Toutiaoplanv2/getProductAvailables', data)

// 查询商品库中商品信息
export const getProductDetail = (data) => get('/v2/Toutiaoplanv2/getProductDetail', data)

// 查询深度优化目标
export const getV2ShenDuoptimizedGoal = (data) => get('/v2/Toutiaoplanv2/getV2ShenDuoptimizedGoal', data)

// 获取账户下项目
export const getProject = (data) => get('/v2/Toutiaoplanv2/getProject', data)


// 根据项目id创建头条广告
export const createV2Promotion = (data) => post('/v2/Toutiaoplanv2/createV2Promotion', data)


// 获取抖音授权关系
export const getAwemeAuthList = (data) => get('/v2/Toutiaoplanv2/getAwemeAuthList', data)

// 获取账户下原生锚点
export const getNativeAnchor = (data) => get('/v2/Toutiaoplanv2/getNativeAnchor', data)



// =====================================MP===============================================

/**
  * MP查询标题组
  * @param {*} data
  * @returns
  */
export const selectMPGroupTitle = (data) => get('/mpGroupTitle/selectGroupTitle', data)
 
/**
  * MP分享标题组
  * @param {*} data
  * @returns
  */
 export const MPshareTitleGroup = (data) => post('/mpGroupTitle/shareTitleGroup', data)

 /**
   * MP修改标题组名称
   * @param {*} data
   * @returns
   */
 export const MPupdateGroupTitleName = (data) => get('/mpGroupTitle/updateGroupTitleName', data)

/**
  * MP复制标题组
  * @param {*} data
  * @returns
  */
 export const MPcopyTitleGroup = (data) => get('/mpGroupTitle/copyTitleGroup', data)

/**
* MP通过标题组id查询素材
* @param {*} data
* @returns
*/
export const MPselectGroupTitleSucai = (data) => get('/mpGroupTitle/selectGroupTitleSucai', data)

/**
  * MP删除标题组
  * @param {*} data
  * @returns
  */
 export const MPdeleteGroupTitle = (data) => get('mpGroupTitle/deleteGroupTitle', data)
/**
* MP删除标题组里面的素材内容
* @param {*} data
* @returns
*/
export const MPdeleteGroupTitleSucai = (data) => get('/mpGroupTitle/deleteGroupTitleSucai', data)

/**
   * MP添加标题组里面素材内容
   * @param {*} data
   * @returns
   */
export const MPinsertGroupTitleSucai = (data) => post('/mpGroupTitle/insertGroupTitleSucai', data)

/**
  * MP输入广告主id查询是否存在
  * @param {*} data
  * @returns
  */
 export const MPselectAccountIdIsNotExsit = (data) => get('/v1/Mp/selectAccountIdIsNotExsit', data)

/**
  * MP添加标题组
  * @param {*} data
  * @returns
  */
 export const mpinsertGroupTitle = (data) => post('mpGroupTitle/insertGroupTitle', data)


 /**
  * mp同步视频到标题组
  * @param {*} data
  * @returns
  */
export const mpsyncTitleGroup = (data) => post('/mpGroupTitle/syncTitleGroup', data)


 /**
  * 查询Mp广告计划创建信息
  * @param {*} data
  * @returns
  */
export const selectMpPlanResult = (data) => get('/v1/Mp/selectMpPlanResult', data)
  

/**
* 创建Mp广告计划查看结果集
* @param {*} data
* @returns
*/
export const selectMpSonData = (data) => get('/v1/Mp/selectMpSonData', data)


 /**
  * MP添加转账账户
  * @param {*} data
  * @returns
  */
 export const addBeikuanAccountId = (data) => get('/v3/tencent/addBeikuanAccountId', data)

 
  /**
  * MP刷新转账账户
  * @param {*} data
  * @returns
  */
  export const refershBeiuanAccountId = (data) => get('/v3/tencent/refershBeiuanAccountId', data)


   /**
  * MP查询所有转账账户
  * @param {*} data
  * @returns
  */
 export const selectBeiuanAccountId = (data) => get('/v3/tencent/selectBeiuanAccountId', data)


  /**
  * 给账户转账
  * @param {*} data
  * @returns
  */
    export const transferAccountId = (data) => post('/v3/tencent/transferAccountId', data)


  /**
  * MP修改转账账户
  * @param {*} data
  * @returns
  */
 export const updateBeiuanAccount = (data) => get('/v3/tencent/updateBeiuanAccount', data)

   /**
  * MP删除转账账户
  * @param {*} data
  * @returns
  */
   export const deleteBeiuanAccount = (data) => get('/v3/tencent/deleteBeiuanAccount', data)

  /**
  * 上传落地页顶部图片
  * @param {*} data
  * @returns
  */
      export const uploadTopImageSpec = (data) => get('/v3/tencent/uploadTopImageSpec', data)

  /**
  * 批量创建原生落地页
  * @param {*} data
  * @returns
  */
   export const createTencentWechatPages = (data) => post('/v3/tencent/createTencentWechatPages', data)


  /**
  * 批量创建官方落地页
  * @param {*} data
  * @returns
  */
     export const createTencentOfficalPages = (data) => post('/v3/tencent/createTencentOfficalPages', data)


 

// =====================================MP  end===============================================
